
  import { Component, Mixins, Prop } from 'vue-property-decorator';
  import ImagesMixin from '@/mixins/images.mixin';
  import { RawLocation } from 'vue-router';

  @Component
  export default class InspiniaGridItem extends Mixins(ImagesMixin) {
    @Prop() title: string;
    @Prop() imageLink?: string;
    @Prop() shortDescription?: string;
    @Prop() link: RawLocation;
  }
