
  import { Component, Vue, Prop } from 'vue-property-decorator';
  import { SimpleTableHeaderItem, SimpleTableRowItem } from '@/types/search';

  @Component
  export default class SimpleTable extends Vue {
    @Prop() fields: SimpleTableHeaderItem[];
    @Prop() entities: SimpleTableRowItem[];
    @Prop({ default: 'Nothing found' }) noEntitiesLabel: string;
    @Prop() withActions: boolean;
    @Prop() actionColClass: string;
  }
