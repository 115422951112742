
  import { SimpleTableRowItem, SimpleTableHeaderItem } from '@/types/search';
  import { Component, Vue, Prop } from 'vue-property-decorator';
  import SimpleTable from '@/components/SimpleTable.vue';

  @Component({
    components: { SimpleTable }
  })
  export default class SearchResultsTable extends Vue {
    @Prop() fields: SimpleTableHeaderItem[];
    @Prop() entities: SimpleTableRowItem[];
    @Prop() total: number;
    @Prop() withActions: boolean;
    @Prop() actionColClass: string;
    @Prop({ default: 'Nothing found' }) noEntitiesLabel: string;
  }
