
import { Component, Vue } from 'vue-property-decorator';

import HipsterInput from '@/components/HipsterInput.vue';
import HipsterSelector from '@/components/HipsterSelector.vue';
import HipsterCheckbox from '@/components/HipsterCheckbox.vue';
import InspiniaGridItem from '@/components/InspiniaGridItem.vue';

import { SearchReferralCodesParams } from '@/types/search';
import { SimpleTableRowItem, SimpleTableHeaderItem } from '@/types/search';
import { ReferralCode } from '@/types/entities';
import SearchResultsTable from '@/components/SearchResultsTable.vue';
import { debounce } from 'lodash';
import { DELETE_REFERRAL_CODE, FETCH_REFERRAL_CODES } from '@/store/referralCodes';

@Component({
  components: { SearchResultsTable, HipsterCheckbox, HipsterInput, HipsterSelector, InspiniaGridItem }
})
export default class ReferralCodesList extends Vue {
  public tableHeader: SimpleTableHeaderItem[] = [
    { class: 'col-lg-3', name: 'Referral Code' },
    { class: 'col-lg-8', name: 'Description' }
  ];

  public filters: SearchReferralCodesParams = {
    query: '',
    per_page: 12,
    page: 1
  };

  public get referralCodes(): SimpleTableRowItem[] {
    return this.$store.state.referralCodes.referralCodesList?.data.map(
        (referralCode: ReferralCode): SimpleTableRowItem => (
            {
              to: `/referral-codes/${referralCode.id}`,
              valueToAction: referralCode.id,
              line: [
                referralCode.code as unknown as string,
                referralCode.description as unknown as string
              ]
            }
        )) || [];
  }

  public doSearchDebounced: ((filter: SearchReferralCodesParams) => void) = debounce(this.search, 300);

  public created(): void {
    this.search({});
  }

  public search(filter: SearchReferralCodesParams): void {
    if (!filter.page) {
      filter.page = 1;
    }

    this.$store.dispatch(FETCH_REFERRAL_CODES, Object.assign(this.filters, filter));
  }

  public deleteReferralCode(referralCodeId: number): void {
    this.$store.dispatch(DELETE_REFERRAL_CODE, referralCodeId).then(() => this.search(this.filters));
  }
}
